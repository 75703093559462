<template>
  <p
    v-if="subtitle"
    class="text-center subtitle"
  >
    {{ subtitle }}
  </p>

  <ZOverlay :show="isExternalAuthenticationLoading">
    <LazyFormSignup
      v-if="authenticationProvider === 'Email' && authenticationType === 'Signup'"
      :owner-signup="authenticationIntent === 'ListingIntent'"
    />

    <LazyFormLogin v-else-if="authenticationProvider === 'Email' && authenticationType === 'Login'" />

    <template v-else>
      <p class="text-center toggle-text">
        <template v-if="isSignupVisible">
          {{ t('auth.haveAnAccount') }}
          <a
            :href="localePath('index-signin')"
           
            @click.prevent="toggleAuthForm"
          >{{ t('auth.login') }}</a>
        </template>
        <template v-else>
          {{ t('auth.noAccount') }}
          <a
            :href="localePath('index-signup')"
            @click.prevent="toggleAuthForm"
          >{{ t('auth.signUp') }}</a>
        </template>
      </p>

      <FormAuthProviders
        :auth-type="authenticationType"
        :intent="authenticationIntent"
        :redirect-path="redirectPath"
        class="mb-4"
        @show-login-form="updateEmailForm('Login')"
        @show-signup-form="updateEmailForm('Signup')"
      />
    </template>
  </ZOverlay>

  <I18nT
    v-if="isSignupVisible"
    keypath="auth.terms.text"
    tag="p"
    class="mt-4 mb-0 terms"
  >
    <NuxtLink
      target="_blank"
      :to="localePath('terms-service')"
    >{{ t('auth.terms.yearsOld') }}</NuxtLink>
    <NuxtLink
      target="_blank"
      :to="localePath('terms-service')"
    >
      {{ t('auth.terms.termsOfService') }}
    </NuxtLink>
    <NuxtLink
      target="_blank"
      :to="localePath('privacy-policy')"
    >
      {{ t('auth.terms.privacyPolicy') }}
    </NuxtLink>
  </I18nT>
</template>

<script setup lang="ts">
import type { AUTHENTICATION_TYPE } from '~/constants/authentication'

defineProps<{
  redirectPath?: string
}>()

const emit = defineEmits<{
  'toggle-auth-form': []
}>()

const { t } = useI18n()

const localePath = useLocalePath()

const { authenticationType, authenticationProvider } = useAuthentication()

function updateEmailForm(value: AUTHENTICATION_TYPE) {
  authenticationType.value = value
  authenticationProvider.value = 'Email'
}

const { isExternalAuthenticationLoading } = useExternalAuthentication()

const { authenticationIntent, enquiryOwnerName, isSignupVisible } = useSignup()

const subtitle = computed(() => {
  switch (authenticationIntent.value) {
    case 'BookingIntent':
      return t('auth.bookingIntentSubtitle')

    case 'EnquiryIntent':
      return t('auth.enquiryIntentSubtitle', { name: enquiryOwnerName.value })

    default:
      return ''
  }
})

function toggleAuthForm() {
  emit('toggle-auth-form')
}
</script>

<style lang="scss" scoped>
p {
  @include caption;

  a {
    @include caption-strong;
    text-decoration: underline;
  }
}
.toggle-text a {
  color: getColor('primary-500');
}
.subtitle,
.terms {
  color: getColor('primary-350');

  a {
    @include caption-strong;
    color: getColor('primary-350');
  }
}
</style>

<i18n src="~/locales/common/auth.json" lang="json" />
